import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import exchange_add_calendar_1 from '../../../../../../../common/src/assets/image/exchange_add_calendar_1.png';
import exchange_add_calendar_2 from '../../../../../../../common/src/assets/image/exchange_add_calendar_2.png';
import exchange_add_calendar_3 from '../../../../../../../common/src/assets/image/exchange_add_calendar_3.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        containing events, appointments, meetings, and tasks imported from
        Microsoft Exchange Server. If your organization uses Exchange Online (part of Office 365), see the {' '}
        <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Outlook-Local">
          Microsoft 365 / Outlook 365
        </Link>{' '}
        instructions instead. To add an Exchange Server calendar source,
        follow these steps:
      </p>
      <ol className="numbered">
        <li>
          <span>Click the </span>
          <strong>Add Calendar </strong>
          <span>button on the </span>
          <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources">
            Calendar Data Sources
          </Link>{' '}
          step and select the <strong>Online -&gt; Exchange Server</strong>{' '}
          option. The <strong>Add Calendar Source - Exchange Server</strong>{' '}
          window will appear.
          <br />
          <Image alt="" src={exchange_add_calendar_1} style={{ width: 590 }} />
        </li>
        <li>
          If you want to connect to a shared resource, such as a room or a
          shared calendar, enter the mailbox name of that shared resource and
          uncheck the <strong>Use default credentials</strong> box. If you want
          to connect to a calendar that is under your account, enter your email
          address for the mailbox name. If you use the same login information
          for Exchange Server as you do for logging into Windows, keep the{' '}
          <strong>Use default login credentials</strong> box checked and
          continue to step 4. Otherwise, uncheck that box.
        </li>
        <li>
          If your Exchange Server is hosted online by Office 365, Rackspace, or
          any other online Exchange Server, uncheck the{' '}
          <strong>Use default login credentials box</strong>. Enter your
          username and password. The domain name can be left blank in most
          cases.
          <br />
          <Image alt="" src={exchange_add_calendar_2} style={{ width: 590 }} />
        </li>
        <li>
          Click the <strong>Connect </strong>button and wait a few seconds for
          PrintableCal to connect. If you get a connection error, check your
          firewall settings. If you use a proxy server, make sure to enter the
          proxy settings via the{' '}
          <strong><Link to="/Documentation/Options">Options</Link>
          </strong> button on the PrintableCal tab of the Ribbon.
        </li>
        <li>
          After a connection is established, the window will update to show your
          calendars, as well as any public or shared calendars. Check the boxes
          next to the calendar names you'd like to add. <br />
          <Image alt="" src={exchange_add_calendar_3} style={{ width: 590 }} />
        </li>
        <li>
          When finished selecting the desired calendar data sources, click the{' '}
          <strong>OK</strong> button.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Exchange-Server"
      commentsId="commentsplus_post_135_489"
      title="Print Calendars with Events, Appointments, and Tasks from Exchange Server"
      description="PrintableCal can create printable calendars containing events, appointments, meetings, and tasks imported from Microsoft Exchange Server."
      keywords="Microsoft Exchange, Exchange Server, printable calendar, Excel calendar, Word calendar, import events, import appointments, import meetings, print events, print appointments"
      content={content}
    />
  );
};

export default Documentation;
